var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      staticClass: "mb-3",
      attrs: { header: _vm.$t("invoices.familyMembers") },
    },
    [
      _c("b-card-body", [
        _c(
          "div",
          { staticClass: "checkboxes text-left" },
          [
            _c(
              "b-form-group",
              [
                _c("b-form-checkbox-group", {
                  attrs: { options: _vm.options },
                  model: {
                    value: _vm.selectedMembers,
                    callback: function ($$v) {
                      _vm.selectedMembers = $$v
                    },
                    expression: "selectedMembers",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", {}, [
          _c("table", { staticClass: "table borderless summary-table" }, [
            _c("thead", [
              _c("tr", [
                _c("th"),
                _c("th", { staticClass: "text-left" }, [
                  _vm._v(_vm._s(_vm.$t("invoices.totalInvoiced"))),
                ]),
                _c("th", { staticClass: "text-left" }, [
                  _vm._v(_vm._s(_vm.$t("invoices.totalPaid"))),
                ]),
                _c("th", { staticClass: "text-left" }, [
                  _vm._v(_vm._s(_vm.$t("invoices.totalOpen"))),
                ]),
              ]),
            ]),
            _c(
              "tbody",
              [
                _vm._l(_vm.items, function (item) {
                  return _c("tr", { key: item.member.id }, [
                    _c("td", [_vm._v(_vm._s(item.member.firstName))]),
                    _c("td", { staticClass: "text-left" }, [
                      _vm._v("€ " + _vm._s(_vm.getPrice(item.invoiced))),
                    ]),
                    _c("td", { staticClass: "text-left" }, [
                      _vm._v("€ " + _vm._s(_vm.getPrice(item.paid))),
                    ]),
                    _c("td", { staticClass: "text-left" }, [
                      _vm._v("€ " + _vm._s(_vm.getPrice(item.open))),
                    ]),
                  ])
                }),
                _c(
                  "tr",
                  {
                    staticClass: "summary-row",
                    staticStyle: { "font-weight": "600" },
                  },
                  [
                    _c("td", [_vm._v(_vm._s(_vm.$t("invoices.total")))]),
                    _c("td", { staticClass: "text-left" }, [
                      _vm._v("€ " + _vm._s(_vm.getInvoiced)),
                    ]),
                    _c("td", { staticClass: "text-left" }, [
                      _vm._v("€ " + _vm._s(_vm.getPaid)),
                    ]),
                    _c("td", { staticClass: "text-left" }, [
                      _vm._v("€ " + _vm._s(_vm.getOpen)),
                    ]),
                  ]
                ),
              ],
              2
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }