var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { header: _vm.$t("invoices.details") } },
    [
      _c("b-card-body", [
        _vm.data.length === 0
          ? _c("div", { staticClass: "w-100 text-center p-3" }, [
              _vm._v(" " + _vm._s(_vm.$t("invoices.noInvoices")) + " "),
            ])
          : _c(
              "div",
              [
                _c("b-table", {
                  staticClass: "details-table",
                  attrs: { items: _vm.tableValues, fields: _vm.tableHeaders },
                  scopedSlots: _vm._u([
                    {
                      key: "cell(show_details)",
                      fn: function (row) {
                        return [
                          _c(
                            "i",
                            {
                              staticClass: "pointer",
                              attrs: { variant: "link", size: "xs" },
                              on: { click: row.toggleDetails },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(row.detailsShowing ? "-" : "+") +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(member)",
                      fn: function (row) {
                        return [
                          _vm._v(" " + _vm._s(row.item.member.firstName) + " "),
                        ]
                      },
                    },
                    {
                      key: "cell(invoiceDate)",
                      fn: function (row) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getDate(row.item.invoiceDate)) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(amount)",
                      fn: function (row) {
                        return [
                          _vm._v(
                            " € " + _vm._s(_vm.getPrice(row.item.amount)) + " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(paid)",
                      fn: function (row) {
                        return [
                          _vm._v(
                            " € " + _vm._s(_vm.getPrice(row.item.paid)) + " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(open)",
                      fn: function (row) {
                        return [
                          _vm._v(
                            " € " + _vm._s(_vm.getPrice(row.item.open)) + " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(pdf_btn)",
                      fn: function (row) {
                        return [
                          _vm.showPdfButton(row.item)
                            ? _c("i", {
                                staticClass: "pointer far fa-file-pdf",
                                attrs: {
                                  title: _vm.$t("invoices.downloadPdf"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.download(
                                      row.item.id,
                                      row.item.fileId
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "cell(lcon_btn)",
                      fn: function (row) {
                        return [
                          _vm.showLconButton(row.item)
                            ? _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.bottomleft",
                                      modifiers: {
                                        hover: true,
                                        bottomleft: true,
                                      },
                                    },
                                  ],
                                  attrs: {
                                    size: "xs",
                                    variant: "link",
                                    disabled: row.item.lconUrl === null,
                                    title: _vm.$t("invoices.goToLcon"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToLcon(row.item)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "pointer fas fa-arrow-circle-right",
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "row-details",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c(
                                "b-card",
                                {
                                  staticClass:
                                    "p-2 mr-1 d-flex flex-grow-1 flex-basis-0",
                                  attrs: {
                                    "sub-title": _vm.$t(
                                      "invoices.invoiceLines"
                                    ),
                                  },
                                },
                                [
                                  row.item.invoiceLinesWithoutPayments.length >
                                  0
                                    ? _vm._l(
                                        row.item.invoiceLinesWithoutPayments,
                                        function (invoiceLine, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "d-flex",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-sm-left flex-grow-1 flex-basis-0",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getDate(
                                                        invoiceLine.date
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-sm-left flex-grow-3 flex-basis-0",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(invoiceLine.lineName)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-sm-left flex-grow-1 flex-basis-0",
                                                },
                                                [
                                                  _vm._v(
                                                    "€ " +
                                                      _vm._s(
                                                        _vm.getPrice(
                                                          invoiceLine.amount
                                                        )
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      )
                                    : [
                                        _c("div", [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "invoices.noInvoiceLines"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                      ],
                                ],
                                2
                              ),
                              _c(
                                "b-card",
                                {
                                  staticClass:
                                    "p-2 ml-1 d-flex flex-grow-1 flex-basis-0",
                                  attrs: {
                                    "sub-title": _vm.$t("invoices.payments"),
                                  },
                                },
                                [
                                  row.item.paidInvoiceLines.length > 0
                                    ? _vm._l(
                                        row.item.paidInvoiceLines,
                                        function (invoiceLine, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "d-flex",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-sm-left flex-grow-1 flex-basis-0",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getDate(
                                                        invoiceLine.date
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-sm-left flex-grow-3 flex-basis-0",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(invoiceLine.lineName)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-sm-left flex-grow-1 flex-basis-0",
                                                },
                                                [
                                                  _vm._v(
                                                    "€ " +
                                                      _vm._s(
                                                        _vm.getPrice(
                                                          invoiceLine.amount
                                                        )
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      )
                                    : [
                                        _c("div", [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("invoices.noPayments")
                                              )
                                            ),
                                          ]),
                                        ]),
                                      ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }