export interface InvoiceResponse {
    total: InvoiceTotal,
    invoices: Invoice[]
}

export interface InvoiceTotal {
    invoiced: number;
    paid: number;
    open: number;
    membersTotal: InvoiceMemberTotal[];
}

export interface InvoiceMemberTotal {
    member: InvoiceMemberSimple;
    invoiced: number;
    paid: number;
    open: number;
}

export interface Invoice {
    id: number;
    member: InvoiceMemberSimple;
    invoiceDate: Date;
    amount: number;
    paid: number;
    open: number;
    isOwn: boolean;
    type: number;
    fileId: number | null;
    lconUrl: string | null;
    externalId: string | null,
    paidInvoiceLines: InvoiceLine[];
    invoiceLinesWithoutPayments: InvoiceLine[];
}

export interface InvoiceLine {
    date: Date;
    lineName: string;
    amount: number;
    type: string;
}

export interface InvoiceMemberSimple {
    id: string;
    firstName: string;
    lastName: string;
    middleName: string;
}

export enum InvoiceType {
    ClubCollect = 0,
    iDeal = 1,
    LightInvoicing = 2,
    LisaContributie = 3
}