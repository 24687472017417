































import { Component, Vue, Watch } from "vue-property-decorator";
import { inject } from 'inversify-props';
import { Action, Getter } from 'vuex-class';
import { IInvoiceApiService, identifier } from "../services/invoices.service";
import { SeasonSelectItem } from "../models/season-select-item.model";
import { InvoiceResponse } from "../models/invoice.model";

import InvoiceFamilySummary from './components/InvoicesFamilySummary.vue';
import InvoicesList from './components/InvoicesList.vue';

@Component({
    components: {
        'invoices-list': InvoicesList,
        'invoices-family-summary': InvoiceFamilySummary
    }
})
export default class Invoices extends Vue {

    invoicesLoaded = false;
    invoices: InvoiceResponse | null = null;

    seasons: SeasonSelectItem[] = [];
    selectedSeason = '';


    @inject(identifier)
    private invoicesApiService!: IInvoiceApiService;

    @Getter
    private clubId!:string;

    @Action
    private setLoading!: (loading: boolean) => Promise<void>

    async created () {
        await this.loadSeasons();
    }

    private async loadSeasons () : Promise<void> {
        try {
            await this.setLoading(true);

            const response = await this.invoicesApiService.getSeasons(this.clubId);

            this.seasons = [];

            response.forEach(x => { 
                this.seasons.push({ value: x.value, text: x.text });

                if (x.selected) {
                    this.selectedSeason = x.value;
                }
            });
        }
        catch (ex) {
            console.error(ex);
        }
        finally {
            await this.setLoading(false);
        }
    }

    private async loadInvoices () : Promise<void> {
        try {
            this.invoicesLoaded = false;
            this.invoices = await this.invoicesApiService.getInvoices(this.clubId, this.selectedSeason);              
        }
        catch (ex) {
            console.error(ex);
        }
        finally {
            this.invoicesLoaded = true;
        }
    }

    @Watch('selectedSeason')
    async selectedSeasonChanged() : Promise<void> {
        await this.loadInvoices();
    }
}
