







































































































import { inject } from 'inversify-props';
import { DateHelper } from '@/shared/helpers/date.helper';
import { PriceHelper } from '@/shared/helpers/price.helper';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Invoice, InvoiceType } from '../../models/invoice.model';
import { identifier, IInvoiceApiService } from '../../services/invoices.service';
import { Getter } from 'vuex-class';
import { ToastHelper } from '@/shared/helpers/toast.helper';

@Component
export default class InvoicesList extends Vue {

    @inject(identifier)
    private invoicesApiService!: IInvoiceApiService;

    @Prop() 
    data!: Invoice[];
    @Prop()
    hasMembersTotal!: boolean;

    @Getter
    private clubId!:string;    
    @Getter
    getSelectedMembers!: string[];
    
    get tableHeaders () : { key: string, label: string, thStyle: any, tdClass: any }[] {
        return [
            { key: 'show_details', label: '', thStyle: {textAlign: "center" }, tdClass: 'text-center' },
            { key: 'externalId', label: `${this.$t('invoices.inoiceId')}`, thStyle: { textAlign: "center" }, tdClass: 'text-center' },
            { key: 'member', label: `${this.$t('invoices.member')}`, thStyle: { textAlign: "center" }, tdClass: 'text-center' },
            { key: 'invoiceDate', label: `${this.$t('invoices.invoiceDate')}`, thStyle: { textAlign: "center" }, tdClass: 'text-center' },            
            { key: 'amount', label: `${this.$t('invoices.amount')}`, thStyle: { textAlign: "center" }, tdClass: 'text-center' },
            { key: 'paid', label: `${this.$t('invoices.paid')}`, thStyle: { textAlign: "center" }, tdClass: 'text-center' },
            { key: 'open', label: `${this.$t('invoices.open')}`, thStyle: { textAlign: "center" }, tdClass: 'text-center' },
            { key: 'pdf_btn', label: `${this.$t('invoices.pdf')}`, thStyle: { textAlign: "center" }, tdClass: 'text-center' },
            { key: 'lcon_btn', label: `${this.$t('invoices.lcon')}`, thStyle: { textAlign: "center" }, tdClass: 'text-center' },
        ]
    }

    get tableValues() : Invoice[] {
        if (!this.hasMembersTotal) {
            return this.data;
        }

        const temp: Invoice[] = [];

        this.data.forEach(x => {
            if (this.getSelectedMembers.includes(x.member.id)) {
                temp.push(x);
            }
        })

        return temp;
    }

    getPrice (value: number) : string {
        return PriceHelper.getPrice(value);
    }

    getDate(value: string) : string {
        return DateHelper.parseDate(value, 'D-M-YYYY');
    }

    showPdfButton(item: Invoice) : boolean {
        if (item.type === null || item.fileId === null) {
            return false;
        }
        else if (item.type === InvoiceType.LightInvoicing || item.type === InvoiceType.LisaContributie) {
            return true;
        }

        return false;
    }

    showLconButton(item: Invoice) : boolean {
        if (item.type === null) {
            return false;
        }
        else if (item.type === InvoiceType.LisaContributie) {
            return true;
        }

        return false;
    }

    goToLcon(item: Invoice) : void {
        if (item.lconUrl === null) {
            return;
        }

        let url = item.lconUrl;

        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = `https://${url}`;
        }

        window.open(url, "_blank");
    }

    async download(invoiceId: number, fileId: number) : Promise<void> {
        try {            
            ToastHelper.showSuccess(this.$bvToast, this.$t('invoices.downloadStarted'));

            const blob = await this.invoicesApiService.downloadInvoice(this.clubId, invoiceId, fileId);

            const url = window.URL.createObjectURL(blob.data);
            const link = document.createElement('a');

            let fileName = `${this.$t('invoices.fileName')}`;

            if (blob.fileName) {
                fileName += `_${blob.fileName}`;
            }

            link.href = url;
            link.download = fileName;
            
            document.body.appendChild(link);
            link.click();
        }
        catch (ex) {
            ToastHelper.showError(this.$bvToast, this.$t('common.errorMessage'));
        }
    }
}
