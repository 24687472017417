










































import { PriceHelper } from '@/shared/helpers/price.helper';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { InvoiceMemberTotal, InvoiceTotal } from '../../models/invoice.model';

@Component
export default class InvoiceFamilySummary extends Vue {

    @Prop() data!: InvoiceTotal;

    @Getter
    getSelectedMembers!: string[];

    get selectedMembers(): string[] {
        return this.getSelectedMembers;
    }
    set selectedMembers(val: string[]) {
        this.$store.commit('setTeamMatches', val);
    }

    created () {
        this.selectedMembers = this.data.membersTotal.map(x => x.member.id);
    }

    get items() : InvoiceMemberTotal[] {
        const temp : InvoiceMemberTotal[] = [];

        this.data.membersTotal.forEach(x => {
            if (this.selectedMembers.includes(x.member.id)) {
                temp.push(x);
            }
        })

        return temp;
    }

    get options() : { value: string, text: string }[] {
        const temp: { value: string, text: string }[] = [];

        this.data.membersTotal.forEach(x => temp.push({ 
            value: x.member.id, 
            text: `${x.member.firstName}` 
        }));

        return temp;
    }

    get getInvoiced() : string {
        const value = this.items.reduce((a, b) => a + (b.invoiced || 0), 0);
        return this.getPrice(value);
    }
    
    get getPaid() : string {
        const value = this.items.reduce((a, b) => a + (b.paid || 0), 0);
        return this.getPrice(value);
    }
    
    get getOpen() : string {
        const value = this.items.reduce((a, b) => a + (b.open || 0), 0);
        return this.getPrice(value);
    }

    getPrice(value: number) : string {
        return PriceHelper.getPrice(value);
    }
}
